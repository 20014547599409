import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#269C37',
    color: 'white',
    display: 'flex',
    padding: '0 36px',
    justifyContent: 'space-between',
  },
  appTitle: {
    color: 'inherit',
    fontSize: 18,
    fontWeight: 700,
    textDecoration: 'none',
  }
});

const PrimaryAppBar = () => {
  const classes = useStyles();
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  return (
    <div className={classes.root}>
      <Link to="/" className={classes.appTitle}>
        RDA Tracker
      </Link>
      {isAuthenticated && (
        <CommandBar
          items={[
            {
              key: 'user',
              text: user.email,
              iconProps: { iconName: 'AccountManagement' },
              subMenuProps: {
                items: [
                  {
                    key: 'logout',
                    text: 'Logout',
                    iconProps: { iconName: 'Leave' },
                    onClick: () => logout({ returnTo: window.location.origin })
                  }
                ]
              },
              // To style the buttons in CommandBar items see:
              // https://developer.microsoft.com/en-us/fluentui#/controls/web/button#IButtonStyles
              buttonStyles: {
                root: {
                  color: 'white',
                  backgroundColor: 'transparent',
                },
                menuIcon: {
                  color: 'white'
                },
                menuIconHovered: {
                  color: 'white',
                },
                icon: {
                  color: 'white'
                },
                iconHovered: {
                  color: 'white'
                },
                rootHovered: {
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.1)'
                }
              }
            }
          ]}
          styles={{
            root: {
              backgroundColor: 'transparent'
            },
          }}
        />
      )}
    </div>
  )
}

export default PrimaryAppBar;