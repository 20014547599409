import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {},
  unorderedList: {
    listStyleType: 'none',
    padding: '16px 24px',
    margin: 0,
  },
  navLink: {
    boxSizing: 'border-box',
    borderRadius: 8,
    color: 'inherit',
    display: 'block',
    textDecoration: 'none',
    padding: '8px 12px',
    marginBottom: 4,
    minWidth: 200,
  },
  activeNavLink: {
    backgroundColor: 'rgba(38,156,55, .1)',
    color: 'rgb(38,156,55)',
    fontWeight: 700,
  }
})

const Drawer = () => {
  const classes = useStyles();

  return (
    <nav>
      <ul className={classes.unorderedList}>
        <li>
          <NavLink
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
            to="/riders"
          >
            Riders
          </NavLink>
        </li>
        <li>
          <NavLink
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
            to="/categories"
          >
            Categories
          </NavLink>
        </li>
        <li>
          <NavLink
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
            to="/tags"
          >
            Tags
          </NavLink>
        </li>
      </ul>
    </nav>
  )
};

export default Drawer;
