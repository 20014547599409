import { useAuth0 } from '@auth0/auth0-react';

type ErrorAccessDeniedProps = {
  error: string,
  description: string
}

const ErrorAccessDenied = ({ error, description }: ErrorAccessDeniedProps) => {
  const { logout } = useAuth0();
  
  return (
    <>
      <h1>Access Denied</h1>
      <p>{description}</p>
      <button onClick={() => logout()}>Logout</button>
    </>
  )
}

export default ErrorAccessDenied;