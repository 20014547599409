import { lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider, PartialTheme } from '@fluentui/react';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from, ServerError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";

import PrimaryAppBar from './components/PrimaryAppBar';
import Drawer from './components/Drawer';

import ErrorAccessDenied from './scenes/ErrorAccessDenied';

// Support code splitting
const ViewAllCategories = lazy(() => import('./scenes/ViewAllCategories'));
const ViewAllTags = lazy(() => import('./scenes/ViewAllTags'));
const ViewAllRiders = lazy(() => import('./scenes/ViewAllRiders'));
const MutateCategory = lazy(() => import('./scenes/MutateCategory'));
const MutateTagValues = lazy(() => import('./scenes/MutateTagValues'))
const MutateRider = lazy(() => import('./scenes/MutateRider'));

const appTheme: PartialTheme = {
  defaultFontStyle: {
    fontFamily: 'DM Sans',
  }
};


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
  },
  appView: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  sceneView: {
    padding: 12,
    width: '100%',
  }
});

// Cache storage for the user token
var token: string;

// Add likely path parameters to interface
type TParams =  { id: string };

const App = () => {
  const classes = useStyles();
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  // Not in React Router context yet so use native JavaScript to get any search params
  // added by Auth0 redirections (for example Access Denied errors)
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());

  if ('error' in searchParams && 'error_description' in searchParams) {
    return <ErrorAccessDenied error={searchParams.error} description={searchParams.error_description} />
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API_URL,
    });

    const authLink = setContext(async () => {
      if (!token) {
        token = await getAccessTokenSilently();
      }

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    });

    const resetToken = onError(({ networkError }) => {
      if (networkError?.name === 'ServerError' && (networkError as ServerError)?.statusCode === 401) {
        // Remove cached token on 401 error from server
        token = null;
      }
    })

    const apolloClient = new ApolloClient({
      link: from([authLink, resetToken, httpLink]),
      cache: new InMemoryCache({
        typePolicies: {
          RiderManagedTag: {
            keyFields: ['id', 'value']
          }
        }
      }),
    });

    return (
      <Router>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={appTheme}>
            <div className={classes.root}>
              <PrimaryAppBar />
              <div className={classes.appView}>
                <Drawer />
                <div className={classes.sceneView}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                      <Route path="/categories/create" component={MutateCategory} />
                      <Route path="/categories" component={ViewAllCategories} />
                      <Route path="/tags/edit/:id" component={MutateTagValues} />
                      <Route path="/tags" component={ViewAllTags} />
                      <Route path="/riders/create" component={MutateRider} />
                      <Route path="/riders/:id" component={MutateRider} />
                      <Route path="/riders" component={ViewAllRiders} />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </ApolloProvider>
      </Router>
    )
  } else {
    // Automatically redirect user to Auth0 login screen
    loginWithRedirect();
  }

  return <div>Loading...</div>
}

export default App;
